<template>
  <CrossgridDashboardLayout sidebar-size="basis-1/3">
    <template #sidebar>
      <CrossgridSidebar :sidebar-items="PRO_SIDEBAR_ITEMS" />
    </template>
    <template #main>
      <CrossgridCard
        title="Business Hours"
        description="Your current availability for customers to book you."
        size="md:max-w-2xl"
        action-button="Save"
        :errors="errors"
        :action-button-disabled="isSaved || isLoading"
        :cancel-button="null"
        @action-click="sendRequest">
        <template #main>
          <CrossgridProAvailability />
          <CrossgridAlert
            title="Discard Changes"
            message="Are you sure you want to continue without saving your changes?"
            cancel-label="Continue"
            action-label="Save Changes"
            :is-open="showDiscardAlert"
            @action-click="sendRequest"
            @cancel-click="discardChanges" />
        </template>
      </CrossgridCard>
    </template>
  </CrossgridDashboardLayout>
</template>

<script lang="ts" setup>
import {onMounted, ref, Ref} from 'vue';
import {onBeforeRouteLeave} from 'vue-router';

import {PRO_SIDEBAR_ITEMS} from '@/constants/pro-profile';
import {APIStandardError} from '@/types/network';
import {ProBusinessHours} from '@/types/pro-profile';
import {MinimalUser} from '@/types/user';
import {useHttp} from '@/utils/composables';
import {
  addedProAvailability,
  deletedProAvailability,
  isLoading,
  isSaved,
  setProAvailability,
  updatedProAvailability,
} from '@/utils/pro-profile';
import {getSession} from '@/utils/shared';

import CrossgridDashboardLayout from '@/components/_layout/CrossgridDashboardLayout.vue';
import CrossgridAlert from '@/components/atoms/CrossgridAlert.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridProAvailability from '@/components/organisms/CrossgridProAvailability.vue';
import CrossgridSidebar from '@/components/organisms/CrossgridSidebar.vue';

const errors: Ref<APIStandardError | undefined> = ref(undefined);
const showDiscardAlert = ref(false);
const http = useHttp();

const userSession: Ref<MinimalUser | null> = ref(null);
const proListing: Ref<string> = ref('');

const sendRequest = () => {
  isLoading.value = true;
  if (addedProAvailability.value.length > 0) {
    createAvailability(addedProAvailability.value).then(() => {
      setProAvailability(proListing.value);
      showDiscardAlert.value = false;
      if (errors.value === undefined) {
        isSaved.value = true;
        localStorage.removeItem('pro_availability_changes');
      }
    });
  } else if (updatedProAvailability.value.length > 0) {
    updateAvailability(updatedProAvailability.value).then(() => {
      setProAvailability(proListing.value);
      showDiscardAlert.value = false;
      if (errors.value === undefined) {
        isSaved.value = true;
        localStorage.removeItem('pro_availability_changes');
      }
    });
  } else if (deletedProAvailability.value.length > 0) {
    deleteAvailability(deletedProAvailability.value).then(() => {
      setProAvailability(proListing.value);
      showDiscardAlert.value = false;
      if (errors.value === undefined) {
        isSaved.value = true;
        localStorage.removeItem('pro_availability_changes');
      }
    });
  }
};

const createAvailability = (availability: ProBusinessHours[]): Promise<void> => {
  return http
    .post(`/pro/pro_listing/${proListing.value}/availability/`, availability)
    .then(() => {
      isSaved.value = true;
      errors.value = undefined;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

const updateAvailability = (availability: ProBusinessHours[]): Promise<void> => {
  return http
    .put(`/pro/pro_listing/${proListing.value}/availability/`, availability)
    .then(() => {
      isSaved.value = true;
      errors.value = undefined;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

const deleteAvailability = (availability: ProBusinessHours[]): Promise<void> => {
  return http
    .delete(`/pro/pro_listing/${proListing.value}/availability/`, {data: availability})
    .then(() => {
      isSaved.value = true;
      errors.value = undefined;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};

const discardChanges = () => {
  localStorage.removeItem('pro_availability_changes');
  location.reload();
};

onBeforeRouteLeave((to, from, next) => {
  if (to.path !== '/pro/dashboard/availability' && isSaved.value === false) {
    showDiscardAlert.value = true;
  } else {
    localStorage.removeItem('pro_availability_changes');
    next();
  }
});

onMounted(() => {
  getSession().then(session => {
    userSession.value = session;
    proListing.value = userSession.value?.pro_listing || '';
  });
});
</script>
