<template>
  <section
    class="flex w-full flex-col overflow-y-scroll bg-bg-secondary pt-3 dark:bg-bg-tertiary-dark dark:[color-scheme:dark] md:w-2/3">
    <div class="flex items-center space-x-3 p-3">
      <div class="w-full">
        <CrossgridInput :icon="MagnifyingGlassIcon" placeholder="Search messages" />
      </div>

      <AdjustmentsHorizontalIcon
        class="h-6 w-6 shrink-0 text-text-primary dark:text-text-primary-dark"
        aria-hidden="true" />
    </div>
    <div class="">
      <ul>
        <li
          v-for="message in messages"
          :key="message.id"
          class="flex items-center justify-between border-b p-5 transition last:border-none dark:border-border-primary-dark">
          <div :class="[message.unread ? 'bg-brand-600' : '', 'mr-3 h-2 w-2 rounded-full']"></div>
          <div class="mr-3">
            <CrossgridIconButton
              :icon="message.starred ? StarIconSolid : StarIcon"
              size="lg"
              color="secondary"
              @click="toggleStarred(message)" />
          </div>
          <div class="w-full">
            <RouterLink to="">
              <h3 class="text-md font-semibold dark:text-text-primary-dark">
                {{ message.name }}
              </h3>
              <div class="text-sm italic text-text-quaternary dark:text-text-tertiary-dark">
                {{ message.subject }}
              </div>
            </RouterLink>
          </div>

          <CrossgridIconButton :icon="TrashIcon" size="lg" color="secondary" />
        </li>
      </ul>
    </div>
  </section>
</template>

<script lang="ts" setup>
import {StarIcon as StarIconSolid} from '@heroicons/vue/20/solid';
import {StarIcon} from '@heroicons/vue/24/outline';
import {AdjustmentsHorizontalIcon, MagnifyingGlassIcon, TrashIcon} from '@heroicons/vue/24/solid';
import {RouterLink} from 'vue-router';

import CrossgridInput from '@/components/atoms/CrossgridInput.vue';
import CrossgridIconButton from '../atoms/CrossgridIconButton.vue';

const toggleStarred = (message: Message) => {
  message.starred = !message.starred;
};

type Message = {
  id: number;
  name: string;
  time: string;
  subject: string;
  location: string;
  proService: string;
  starred: boolean;
  unread: boolean;
};

const messages: Message[] = [
  {
    id: 1,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: true,
  },
  {
    id: 2,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: true,
  },
  {
    id: 3,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 4,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 5,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 6,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 7,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 8,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 9,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
  {
    id: 10,
    name: 'Akhil Gautam',
    time: '23m ago',
    subject: 'You have been invited!',
    location: 'New York, NY',
    proService: 'Emergency Plumbing',
    starred: false,
    unread: false,
  },
];
</script>
