import {captureException} from '@sentry/vue';
import axios, {AxiosError, AxiosInstance, AxiosResponse} from 'axios';

import {CROSSGRID_API_BASE_URL} from '@/constants/common';

export const useHttp = (): AxiosInstance => {
  const $axios = axios.create({
    headers: {
      Authorization: `Bearer ${import.meta.env.VITE_API_AUTH_BEARER}`,
    },
    baseURL: CROSSGRID_API_BASE_URL,
  });
  $axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    (error: AxiosError) => {
      const errorResponse = error.response;
      if (errorResponse) {
        if (errorResponse.status !== 422) {
          const responseData = errorResponse.data as Record<string, unknown>;
          const unexpectedError = {unexpected_error: 'An unexpected error occurred'};

          if (responseData && typeof responseData === 'object') {
            if (!('unexpected_error' in responseData)) {
              errorResponse.data = unexpectedError;
            }
          } else {
            errorResponse.data = unexpectedError;
          }
        }
      } else {
        captureException(error);
      }
      return Promise.reject(error);
    }
  );
  return $axios as AxiosInstance;
};
