<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        :description="null"
        :custom-description="true"
        :action-button="null"
        :signup-flow="true"
        :errors="errors"
        @cancel-click="handleNavigation('back')">
        <template #title>Get started for free!</template>
        <template #description>
          <p class="my-4 text-center text-text-tertiary dark:text-text-tertiary-dark">
            Unlock all our features at no cost for the first three months! While a credit card is
            needed to activate your account, rest assured, <strong>you won't be charged</strong> for
            the subscription until the end of your trial
          </p>
          <p
            class="border-b-2 border-border-secondary pb-4 text-center text-text-tertiary dark:text-text-tertiary-dark">
            At the end of the trial, you can choose to continue or cancel — no hidden fees or
            surprises. If you decide to stay, you'll be charged just $11 per month.
          </p>
        </template>
        <template #main>
          <div ref="checkoutContainer" class="col-span-full"></div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {onBeforeMount, onBeforeUnmount, onMounted, Ref, ref} from 'vue';
import {loadStripe, StripeEmbeddedCheckout} from '@stripe/stripe-js';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {useHttp} from '@/utils/composables';
import {getProSignupSavedProgress} from '@/utils/pro-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const router = useRouter();
const http = useHttp();

const checkoutContainer = ref('');
const errors: Ref<APIStandardError | undefined> = ref(undefined);
let checkoutInstance: StripeEmbeddedCheckout | null = null;

const initializeStripeCheckout = () => {
  const progress = getProSignupSavedProgress();
  loadStripe(import.meta.env.VITE_STRIPE_PUBLIC_KEY).then(stripe => {
    http
      .post('/stripe/create_checkout_session/', {email: progress?.pro_three?.email})
      .then(response => {
        const clientSecret = response.data.client_secret;
        if (stripe && !checkoutInstance) {
          stripe
            .initEmbeddedCheckout({
              clientSecret,
            })
            .then(checkout => {
              checkoutInstance = checkout;
              checkoutInstance.mount(checkoutContainer.value);
            })
            .then(() => {
              const path = router.currentRoute.value.path;
              const url = `${window.location.origin}${path}`;
              const {first_name, last_name, email, phone_number, zipcode} =
                progress?.pro_three || {};

              http.post('/fbq/events/initiate_checkout/', {
                page_url: url,
                first_name,
                last_name,
                email,
                phone_number,
                zipcode,
              });
            });
        }
      })
      .catch(error => {
        errors.value = error.response?.data;
      });
  });
};

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_three);
};

onMounted(() => {
  initializeStripeCheckout();
});

onBeforeUnmount(() => {
  if (checkoutInstance) checkoutInstance.destroy();
});

onBeforeMount(() => {
  // Make sure that step 3 is done, otherwise redirect user back
  const progress = getProSignupSavedProgress();
  if (!progress || !progress.pro_three)
    return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_three);
});
</script>
