<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        description=""
        :signup-flow="!userSession?.is_authenticated"
        :action-button-disabled="actionButtonDisabled"
        :errors="errors"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #title
          >What service are you <br />
          looking for?</template
        >
        <template #main>
          <div class="col-span-full">
            <CrossgridSearch
              v-model="selectedService"
              :pro-services="proServices"
              placeholder="Plumbing, painting, etc."
              @search-query="(query: string) => handleSearchQuery(query)" />

            <p
              id="business-description"
              class="mt-2 text-xs text-text-tertiary dark:text-text-tertiary-dark">
              You can add additional services in the next section
            </p>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onMounted, ref} from 'vue';
import type {Ref} from 'vue';
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {ProService} from '@/types/pro-services';
import {MinimalUser} from '@/types/user';
import {UserSignupSavedProgress} from '@/types/user-signup';
import {errors, searchProService} from '@/utils/pro-services-search';
import {getSession} from '@/utils/shared';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';
import CrossgridSearch from '@/components/molecules/CrossgridSearch.vue';

const selectedService: Ref<ProService | null> = ref(null);
const proServices: Ref<ProService[]> = ref([]);

const userSession: Ref<MinimalUser | null> = ref(null);

const router = useRouter();

const handleNavigation = (action: string) => {
  if (action === 'back' && userSession.value?.is_authenticated)
    return router.push(CROSSGRID_PAGE_URLS.general.home);
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.general.signup);
  if (selectedService.value) {
    const progress: UserSignupSavedProgress = {user_one: selectedService.value.slug};
    localStorage.setItem('user_signup_progress', JSON.stringify(progress));
    router.push({
      name: CROSSGRID_PAGE_URLS.user.signup.step_two,
      params: {slug: selectedService.value.slug},
    });
  }
};

const actionButtonDisabled = computed(() => {
  return selectedService.value === null;
});

const handleSearchQuery = (searchQuery: string) => {
  if (searchQuery === '') selectedService.value = null;
  searchProService(searchQuery).then(services => (proServices.value = services));
};

onMounted(() => {
  getSession().then(session => (userSession.value = session));
  localStorage.removeItem('user_signup_progress');
});
</script>
