import {ref, Ref} from 'vue';

import {APIStandardError} from '@/types/network';
import {ProService} from '@/types/pro-services';

import {useHttp} from './composables';

export const errors: Ref<APIStandardError | undefined> = ref(undefined);

const http = useHttp();

export const searchProService = (query: string): Promise<ProService[]> => {
  return http
    .post('/pro/pro_service/search/', {
      query: query,
    })
    .then(response => {
      return response.data.items;
    })
    .catch(error => {
      errors.value = error.response?.data;
    });
};
