import {createRouter, createWebHistory} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {useHttp} from '@/utils/composables';

import ProDashboardMessagesInbox from '@/components/partials/ProDashboardMessagesInbox.vue';
import NotFound from '@/pages/Errors/NotFound.vue';
import MarketingHome from '@/pages/Marketing/MarketingHome.vue';
import SignupChoice from '@/pages/Marketing/SignupChoice.vue';
import ProDashboardMessages from '@/pages/Pro/Dashboard/_backburner/ProDashboardMessages.vue';
import ProDashboardAvailability from '@/pages/Pro/Dashboard/ProDashboardAvailability.vue';
import ProDashboard from '@/pages/Pro/Dashboard/ProDashboardIndex.vue';
import ProDashboardJobs from '@/pages/Pro/Dashboard/ProDashboardJobs.vue';
import ProDashboardProfile from '@/pages/Pro/Dashboard/ProDashboardProfile.vue';
import ProProfileSetupOne from '@/pages/Pro/Profile/Setup/ProProfileSetupOne.vue';
import ProProfileSetupTwo from '@/pages/Pro/Profile/Setup/ProProfileSetupTwo.vue';
import ProSignupFive from '@/pages/Pro/Signup/ProSignupFive.vue';
import ProSignupFour from '@/pages/Pro/Signup/ProSignupFour.vue';
import ProSignupOne from '@/pages/Pro/Signup/ProSignupOne.vue';
import ProSignupSuccess from '@/pages/Pro/Signup/ProSignupSuccess.vue';
import ProSignupThree from '@/pages/Pro/Signup/ProSignupThree.vue';
import ProSignupTwo from '@/pages/Pro/Signup/ProSignupTwo.vue';
import UserJobSearchDescription from '@/pages/User/JobSearch/UserJobSearchDescription.vue';
import UserJobSearchSuccess from '@/pages/User/JobSearch/UserJobSearchSuccess.vue';
import UserSignupFour from '@/pages/User/Signup/UserSignupFour.vue';
import UserSignupOne from '@/pages/User/Signup/UserSignupOne.vue';
import UserSignupSuccess from '@/pages/User/Signup/UserSignupSuccess.vue';
import UserSignupThree from '@/pages/User/Signup/UserSignupThree.vue';
import UserSignupTwo from '@/pages/User/Signup/UserSignupTwo.vue';

const http = useHttp();

const {
  pro: {
    signup: {
      step_one: pro_signup_one,
      step_two: pro_signup_two,
      step_three: pro_signup_three,
      step_four: pro_signup_four,
      step_five: pro_signup_five,
      success: pro_signup_success,
    },
    profile: {
      setup: {one: pro_profile_setup_one, two: pro_profile_setup_two},
    },
    dashboard: {
      home: pro_dashboard,
      jobs: pro_dashboard_jobs,
      messages: pro_dashboard_messages,
      availability: pro_dashboard_availability,
      profile: pro_dashboard_profile,
    },
  },
  user: {
    signup: {
      step_one: user_signup_one,
      step_two: user_signup_two,
      step_three: user_signup_three,
      step_four: user_signup_four,
      success: user_signup_success,
    },
    job_search: {description: user_job_search_description, success: user_job_search_success},
  },
  general: {home: general_home, signup: general_signup},
} = CROSSGRID_PAGE_URLS;

const routes = [
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
  {
    path: pro_signup_one,
    name: pro_signup_one,
    component: ProSignupOne,
  },
  {
    path: `${pro_signup_two}:slug/`,
    name: pro_signup_two,
    component: ProSignupTwo,
  },
  {
    path: pro_signup_three,
    name: pro_signup_three,
    component: ProSignupThree,
  },
  {
    path: pro_signup_four,
    name: pro_signup_four,
    component: ProSignupFour,
  },
  {
    path: pro_signup_five,
    name: pro_signup_five,
    component: ProSignupFive,
  },
  {
    path: pro_signup_success,
    name: pro_signup_success,
    component: ProSignupSuccess,
  },
  {
    path: pro_profile_setup_one,
    name: pro_profile_setup_one,
    component: ProProfileSetupOne,
  },
  {
    path: pro_profile_setup_two,
    name: pro_profile_setup_two,
    component: ProProfileSetupTwo,
  },
  {
    path: pro_dashboard,
    name: 'Dashboard',
    component: ProDashboard,
  },
  {
    path: pro_dashboard_jobs,
    name: 'Jobs',
    component: ProDashboardJobs,
  },
  {
    path: pro_dashboard_messages,
    name: 'Messages',
    component: ProDashboardMessages,
    children: [
      {path: '#inbox', name: 'Inbox', component: ProDashboardMessagesInbox},
      {path: '#starred', name: 'Starred', component: ProDashboardMessagesInbox},
      {path: '#pending', name: 'Pending', component: ProDashboardMessagesInbox},
      {path: '#hired', name: 'Hired', component: ProDashboardMessagesInbox},
      {path: '#job_completed', name: 'Job Completed', component: ProDashboardMessagesInbox},
      {path: '#not_hired', name: 'Not Hired', component: ProDashboardMessagesInbox},
      {path: '#closed', name: 'Closed', component: ProDashboardMessagesInbox},
    ],
  },
  {
    path: pro_dashboard_availability,
    name: 'Availability',
    component: ProDashboardAvailability,
  },
  {
    path: pro_dashboard_profile,
    name: 'Profile',
    component: ProDashboardProfile,
  },
  {
    path: user_signup_one,
    name: user_signup_one,
    component: UserSignupOne,
  },
  {
    path: `${user_signup_two}:slug/`,
    name: user_signup_two,
    component: UserSignupTwo,
  },
  {
    path: user_signup_three,
    name: user_signup_three,
    component: UserSignupThree,
  },
  {
    path: user_signup_four,
    name: user_signup_four,
    component: UserSignupFour,
  },
  {
    path: user_signup_success,
    name: user_signup_success,
    component: UserSignupSuccess,
  },
  {
    path: user_job_search_success,
    name: user_job_search_success,
    component: UserJobSearchSuccess,
  },
  {
    path: user_job_search_description,
    name: user_job_search_description,
    component: UserJobSearchDescription,
  },
  {
    path: general_home,
    name: general_home,
    component: MarketingHome,
  },
  {
    path: general_signup,
    name: general_signup,
    component: SignupChoice,
  },
];

const router = createRouter({
  history: createWebHistory('/v/'),
  routes,
  scrollBehavior() {
    return {top: 0};
  },
});

router.afterEach(async to => {
  const path = to.fullPath;
  const url = `${window.location.origin}${path}`;
  http.post('/fbq/events/page_view/', {page_url: url});
});

export default router;
