<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Welcome to Crossgrid!"
        description=""
        :cancel-button="null"
        :action-button-disabled="false"
        action-button="Build My Profile"
        @action-click="handleNavigation()">
        <template #main>
          <div data-e2e="success-message" class="col-span-full">
            <p class="text-center text-lg text-text-tertiary dark:text-text-tertiary-dark">
              Now that your account is created it's time to get you some high quality leads!
            </p>
          </div>
          <div class="col-span-full">
            <p class="text-center text-lg text-text-tertiary dark:text-text-tertiary-dark">
              Lets start by getting your profile built.
            </p>
          </div>
          <div class="col-span-full">
            <p class="text-center text-lg text-text-tertiary dark:text-text-tertiary-dark">
              A complete and detailed profile will get you matched with more quality leads.
            </p>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const router = useRouter();

const handleNavigation = () => {
  return router.push(CROSSGRID_PAGE_URLS.pro.profile.setup.one);
};
</script>
