<template>
  <CrossgridAuthLayout>
    <template #main>
      <CrossgridCard
        title="Slot used"
        description="You will show up in search results and get jobs for all services you select."
        :signup-flow="true"
        :action-button-disabled="actionButtonDisabled"
        :errors="errors"
        @cancel-click="handleNavigation('back')"
        @action-click="handleNavigation('next')">
        <template #title>Select the services<br />you offer</template>
        <template #main>
          <div class="col-span-full">
            <div class="mb-2">
              <button
                type="button"
                :data-e2e="'checkbox-toggle'"
                class="text-md rounded-md py-2.5 font-semibold text-button-primary-bg hover:text-button-primary-bg-hover"
                @click="toggleCheckBoxes()">
                {{ checkedList.length > 1 ? 'Deselect All' : 'Select All' }}
              </button>
            </div>
            <div
              v-if="isLoading"
              class="flex justify-center gap-3 font-semibold text-text-secondary">
              <div
                class="border-button-brand-fg h-5 w-5 animate-spin rounded-full border-2 border-solid border-t-transparent"></div>
              Getting services...
            </div>
            <fieldset
              v-else
              class="border-b border-t border-border-secondary dark:border-border-secondary-dark">
              <legend class="sr-only">Services</legend>
              <div class="divide-y divide-border-secondary dark:divide-border-secondary-dark">
                <div
                  v-for="(service, index) in proListingServicesOffered"
                  :key="index"
                  class="flex items-center py-2">
                  <div class="flex h-6 items-center">
                    <input
                      :id="service.name"
                      v-model="checkedList"
                      :tabindex="0"
                      :value="service.slug"
                      :data-e2e="`pro-service-related-input-${index}`"
                      aria-describedby="pro-services-description"
                      name="pro_services"
                      type="checkbox"
                      class="h-6 w-6 cursor-pointer rounded border-border-primary bg-white text-bg-brand-solid focus:ring-bg-brand-solid dark:border-border-primary-dark dark:bg-bg-primary-dark dark:text-bg-brand-solid-dark dark:focus:ring-bg-brand-solid-dark"
                      @keydown.enter.prevent="toggleCheckBox(service.slug)" />
                  </div>
                  <div class="text-md ml-3 leading-6">
                    <label
                      for="comments"
                      class="font-medium text-text-secondary dark:text-text-secondary-dark"
                      >{{ service.name }}</label
                    >
                  </div>
                </div>
              </div>
            </fieldset>
          </div>
        </template>
      </CrossgridCard>
    </template>
  </CrossgridAuthLayout>
</template>

<script setup lang="ts">
import {computed, onBeforeMount, onMounted, ref} from 'vue';
import type {Ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';

import {CROSSGRID_PAGE_URLS} from '@/constants/common';
import {APIStandardError} from '@/types/network';
import {ProService} from '@/types/pro-services';
import {ProSignupSavedProgress} from '@/types/pro-signup';
import {useHttp} from '@/utils/composables';
import {getProSignupSavedProgress} from '@/utils/pro-signup';

import CrossgridAuthLayout from '@/components/_layout/CrossgridAuthLayout.vue';
import CrossgridCard from '@/components/atoms/CrossgridCard.vue';

const proListingServicesOffered: Ref<ProService[]> = ref([]);
const checkedList: Ref<string[]> = ref(['']);
const errors: Ref<APIStandardError | undefined> = ref(undefined);

const isLoading: Ref<boolean> = ref(true);

const router = useRouter();
const http = useHttp();

const handleNavigation = (action: string) => {
  if (action === 'back') return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_one);
  if (checkedList.value.length > 0) {
    const progress = getProSignupSavedProgress();
    if (progress) {
      progress.pro_two = checkedList.value;
      localStorage.setItem('pro_signup_progress', JSON.stringify(progress));
      return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_three);
    }
  }
};

const toggleCheckBoxes = () => {
  return checkedList.value.length > 1
    ? (checkedList.value = [])
    : (checkedList.value = proListingServicesOffered.value.map(service => service.slug));
};

const toggleCheckBox = (slug: string) => {
  if (checkedList.value.includes(slug)) {
    checkedList.value = checkedList.value.filter(item => item !== slug);
  } else {
    checkedList.value = [...checkedList.value, slug];
  }
};

const actionButtonDisabled = computed(() => {
  return checkedList.value.length < 1 || isLoading.value;
});

const searchProServiceRelatedServices = (progress: ProSignupSavedProgress | null) => {
  http
    .get(`/pro/pro_service/${useRoute().params.slug}/related_services/`)
    .then(response => {
      isLoading.value = false;
      checkedList.value = progress?.pro_two ?? response.data.pre_checked_list;
      proListingServicesOffered.value = response.data.items;
    })
    .catch(error => {
      isLoading.value = false;
      if (error.response?.status == 404) return (window.location.href = '/404/');
      errors.value = error.response?.data;
    });
};

onMounted(() => {
  const progress = getProSignupSavedProgress();
  searchProServiceRelatedServices(progress);
});

onBeforeMount(() => {
  // Make sure that step 1 is done, otherwise redirect user back
  const progress = getProSignupSavedProgress();
  if (!progress || !progress.pro_one) return router.push(CROSSGRID_PAGE_URLS.pro.signup.step_one);
});
</script>
