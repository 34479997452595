import {
  CalendarDaysIcon,
  HomeIcon,
  UserCircleIcon,
  WrenchScrewdriverIcon,
} from '@heroicons/vue/24/solid';

import {SidebarItems, Steps} from '@/types/ui-elements';

export const PRO_PROFILE_SETUP_STEPS: Steps[] = [
  {
    name: 'About you',
    description: 'Help customers in getting to know you a little better.',
    status: 'current',
  },
  {
    name: 'Preferences',
    description: 'Add your business location and hours.',
    status: 'upcoming',
  },
];

export const PRO_SIDEBAR_ITEMS: SidebarItems[] = [
  {displayName: 'Dashboard', routeName: 'Dashboard', icon: HomeIcon},
  {displayName: 'Jobs', routeName: 'Jobs', icon: WrenchScrewdriverIcon},
  {
    displayName: 'Availability',
    routeName: 'Availability',
    icon: CalendarDaysIcon,
  },
  {displayName: 'Profile', routeName: 'Profile', icon: UserCircleIcon},
];
